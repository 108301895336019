
body {
  margin: 0px;
  touch-action: none;
  overflow: hidden;
  background: grey;
  font-family: 'Courier New', monospace;
}

button {
    font-family: 'Courier New',
    monospace;
}

canvas {
  width: 100%;
  image-rendering: pixelated;
  position: absolute;
}

.imageLayer {
  width: 100%;
  image-rendering: pixelated;
  position: absolute;
}

.layeredCanvas {
  /* transform-origin: left top; */
  transform-style: preserve-3d;
  position: absolute;
  width: 100%;
}

.layeredCanvas:before,
.layeredCanvas:after {
  transform: translateZ(-1em);
}

.canvasContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  user-select: none;
}

.topbar {
  position: fixed;
  width: 100%;
  color: white;
  z-index: 1;
}

.topbarRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
}

.topbarRow button {
  color: white;
  background: black;
  border: 0px;
}

.toolbar {
  position: fixed;
  left: 10px;
  top: 40px;
  transform: translate(0%, 0%);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
}

.toolbarGroup {
  background: white;
  border-radius: 28px;
  padding: 4px;
  width: 48px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.toolbarGroup button {
  width: 48px;
  height: 48px;
  font-size: 1.5em;
}

.toolbarButtonIcon {
  width: 100%;
  image-rendering: pixelated;
}


.colorPicker {
  /* position: fixed;
  left: 15px;
  top: 3%; */
  transform: translate(40%, 0%);

}

.colorPickerText {
  width: 80px;
  margin-left: 50%;
  transform: translate(-50%, -50%)
}

.changeColorButton {
  background: white;
  height: 32px;
  width: 32px;
}

.changeColorIcon {
  filter: contrast(1);
  filter: invert(1);
}

.toggleDrawingButton {
  background: grey;
  border: 0px;
  border-radius: 22px;
  color: white;
  display: flex;
  align-items: center;
  padding: 8px;
}

.toggleDrawingButton.selected {
  background: dodgerblue;
}

.controlBar {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0%);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  background: white;
  border-radius: 28px;
  padding: 4px 20px;
  height: 48px;
  align-items: center;
}

.confirmSpinner {
  display: flex;
  align-items: center;
}

.submitButton {
  background: dodgerblue;
  border-radius: 28px;
  color: white;
  border: 0px;
  padding: 8px; 
}

.layersControlBar {
  /* background: white; */
  /* border-radius: 28px; */
  position: fixed;
  right: 10px;
  top: 40px;
  transform: translate(0%, 0%);
  margin: 0 auto;
  display: flex;
  padding: 4px;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  max-height: 70%;

  /* border-radius: 35px;
  padding: 20px 10px; */
}

.layerControlSelectionSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  gap: 4px;
}

.layerControlSelectionSection::-webkit-scrollbar {
  display: none;
}

.layerControlButton {
  width: 100%;
  border: 0px;
  background: darkgray;
  display: flex;
  align-items: center;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
}

.layerControlButton.selected {
  background: dodgerblue;
}

.layerControlImage {
  width: 100%;
  background: grey;
  /* border-radius: 22px; */
}

.layerControlLabel {
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0%);
}

.warningLabel {
  color: red;
  position: fixed;
  left: 10%;
  bottom: 100px;
  text-align: center;
  width:80%;
}

.hoveringUserInfo {
  background: white;
  padding: 5px;
  border-radius: 5px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  font-size: small;
}

.profileOptions {
  display: flex;
  grid-gap: 12px;
  gap: 2px;
  background: black;
  flex-direction: column;
}

.aboutInfoContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.aboutInfo {
  position: fixed;
  left: 10%;
  top: 10%;
  width: 80%;
  max-height: 80%;
  background: white;
  color: black;
  text-align: center;
  padding: 10px;
  overflow-y: scroll;
}

.aboutInfoText {
  padding: 10px 50px 50px;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0px;
  background: white;
  font-size: 1.5em;
}